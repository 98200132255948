<template>
  <v-app-bar app color="light">
    <v-spacer />
    <!---right part -->
    <v-tabs color="primary darken-1">
      <v-tab to="/onlinebooking"> {{ $t("Common.OnlineBooking") }} </v-tab>
      <v-tab to="/patientregistration">
        {{ $t("Common.PatientRegistration") }}
      </v-tab>
      <v-tab to="/queue"> {{ $t("Common.PatientQueue") }} </v-tab>
      <v-tab to="/billing"> {{ $t("Common.BillingQueue") }} </v-tab>
      <!-- <v-tab to="/invoice/business-info">{{ $t('Common.Invoice') }}</v-tab> -->
      <v-tab to="/report/common-report"> {{ $t("Common.Report") }} </v-tab>
      <v-tab to="/dashboard"> {{ $t("Common.Dashboard") }} </v-tab>
      <v-tab to="/roster"> {{ $t("Common.DoctorRoster") }} </v-tab>
    </v-tabs>
    <v-spacer />
    <SwitchLanguage style="margin-top: 20px" />
    <!---User -->
    <v-menu
      bottom
      left
      offset-y
      origin="top right"
      transition="scale-transition"
    >
      <template v-slot:activator="{ on }">
        <!-- User Area -->
        <v-btn light v-on="on" class="elevation-0">
          <v-list-item two-line class="profile-bg">
            <v-list-item-avatar>
              <v-avatar size="32px" color="primary">
                <img
                  v-if="authData.avatar"
                  alt="Avatar"
                  :src="authData.avatar"
                />
                <span v-else class="white--text">
                  {{ getNameInnitial(authData.fullName) }}
                </span>
              </v-avatar>
            </v-list-item-avatar>
            <v-list-item-content class="black--text text-left no-padding">
              <v-list-item-title>{{ authData.fullName }}</v-list-item-title>
              <v-list-item-subtitle class="caption gray--text">{{
                authData.userTypeName
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-btn>
        <!-- End user area -->
      </template>

      <v-list>
        <v-list-item
          v-for="(item, i) in userprofile"
          :key="i"
          @click="triggerClick(item.logout)"
        >
          <v-list-item-title>{{ item.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <!---User -->
  </v-app-bar>
</template>
<script>
// Utilities
import { mapState, mapMutations } from "vuex";
import { getNameInnitial } from "@/plugins/helper";
import SwitchLanguage from "@/components/SwitchLanguage";
import i18n from "@/plugins/i18n";

export default {
  name: "Header",
  components: {
    SwitchLanguage,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    showLogo: true,
    showSearch: false,
    notifications: [
      {
        title: "Launch Admin",
        iconbg: "error",
        icon: "mdi-link-variant",
        desc: "Just see the my new admin!",
        time: "9:30AM",
      },
      {
        title: "Event today",
        iconbg: "success",
        icon: "mdi-calendar-check",
        desc: "Just a reminder that you have event",
        time: "10:30AM",
      },
      {
        title: "Settings",
        iconbg: "info",
        icon: "mdi-cog",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        iconbg: "indigo",
        icon: "mdi-account",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    messages: [
      {
        title: "Sonu Nigam",
        avatar: "1",
        avatarstatus: "success",
        desc: "Singing Show tonigh at 9pm!",
        time: "9:30AM",
      },
      {
        title: "Sonu Nigam",
        avatar: "2",
        avatarstatus: "error",
        desc: "The greate malody songs ever sung",
        time: "10:30AM",
      },
      {
        title: "Arijit singh",
        avatar: "3",
        avatarstatus: "warning",
        desc: "You can customize this template as you want",
        time: "11:30AM",
      },
      {
        title: "Pavan Kumar",
        avatar: "4",
        avatarstatus: "success",
        desc: "Sent you an notification",
        time: "12:30AM",
      },
    ],
    userprofile: [
      { title: i18n.t("Revenue.PersonalQueue"), logout: "personalQueue" },
      // { title: "Account Setting", logout: [] },
      {
        title: i18n.t("Common.LogOut"),
        logout: "handleLogout",
      },
    ],
    href() {
      return undefined;
    },
  }),

  computed: {
    ...mapState(["Sidebar_drawer"]),
    authData() {
      return (
        this.$store.getters.userData || {
          fullName: "",
          avatar: "",
          userTypeName: "",
        }
      );
    },
  },

  methods: {
    ...mapMutations({
      setSidebarDrawer: "SET_SIDEBAR_DRAWER",
    }),
    getNameInnitial,
    triggerClick(handleLogout) {
      if (handleLogout == "handleLogout") {
        this.$store.commit("CLEAR_ACCESS_TOKEN");
        this.$store.commit("CLEAR_USER_DATA");
        this.$router.push({ path: "/login" });
      } else if (handleLogout == "personalQueue") {
        this.$router.push({ path: "/queue/personal" });
      }
    },
    showhideLogo: function() {
      this.showLogo = !this.showLogo;
    },
    searchbox: function() {
      this.showSearch = !this.showSearch;
    },
  },
};
</script>

<style lang="scss">
.v-application #main-sidebar.theme--dark.white {
  background: #363636 !important;
}
</style>
