.<template>
  <div class="d-flex">
    <v-switch
      inset
      class="switch-language d-flex"
      v-model="isVnLocale"
      @change="switchLocale"
    >
    </v-switch>
    <CountryFlag :country='flagLang' size='normal'/>
  </div>
</template>

.<script>
import CountryFlag from 'vue-country-flag'
export default {
  data() {
    return {
      flagLang: this.$i18n.locale,
      locale: this.$i18n.locale,
      isVnLocale: this.$i18n.locale == "vn",
    };
  },
  components: {
    CountryFlag,
  },
  methods: {
    switchLocale() {
      if (this.locale == "us") {
        localStorage.setItem("lang", "vn")
      }
      else {
        localStorage.setItem("lang", "us")
      }
      setTimeout(() => {
        this.$router.go()
      }, 200)
    },
  },
};
</script>
.<style lang="scss">
.switch-language.v-input--switch {
  label.v-label {
    top: -2px;
  }
}
// .icon-switch-lang {
//   border-radius: 50%;
//   width: 200px;
// }
</style>