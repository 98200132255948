<template>
  <v-snackbar class="v-notify" :color="type" v-model="show">
    {{message}}
    <div class="badge" v-if="badgeNumber > 0 && isShowBadge">
      {{badgeNumber}}
    </div>
  </v-snackbar>
</template>

<script>

export default {
  name: 'v-notify',
  props: {
    isShowBadge: {
      type: Boolean,
      default: true
    },
  },
  computed: {
    message () {
      return this.$store.state.Notify.message
    },
    type () {
      return this.$store.state.Notify.type
    },
    badgeNumber () {
      return this.$store.state.Notify.count
    },
    show: {
      get () { return this.$store.state.Notify.show },
      set (value) { this.$store.commit('SET_SHOW', value) }
    }
  },
  data: () => ({
  }),
  created() {},
  methods: {
  },
}
</script>
<style lang="scss" scoped>
  .v-notify {
    display: flex !important;
    .badge {
      position: absolute;
      top: -16px;
      left: -16px;
      border-radius: 50%;
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.8);
    }
  }
</style>