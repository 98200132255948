<template>
  <v-app id="inspire">
    <Header class="d-print-none" v-model="expandOnHover"></Header>

    <v-main>
      <v-container fluid class="page-wrapper">
        <router-view />
      </v-container>
    </v-main>

    <v-notify />
  </v-app>
</template>

<script>
import Header from "./header/Header";
// import { mapState, mapMutations } from 'vuex';
import VNotify from "../../components/commonComponents/Notify/Notify";

export default {
  name: "Layout",

  components: {
    Header,
    VNotify,
  },

  props: {
    source: String,
  },
  data: () => ({
    expandOnHover: false,
  }),
  computed: {
    // ...mapState(['Customizer_drawer']),
  },

  methods: {
    // ...mapMutations({
    //   setCustomizerDrawer: 'SET_CUSTOMIZER_DRAWER',
    // }),
  },
};
</script>

<style>
.v-input * {
  color: #000 !important;
}
.v-skeleton-loader__text {
  height: 14px !important;
  margin-bottom: 15px !important;
}
.v-skeleton-loader__list-item-three-line,
.v-skeleton-loader__list-item-avatar-three-line {
  height: 88px;
  padding: 0px !important;
}
.v-skeleton-loader__bone {
  padding-left: 0px !important;
}
</style>